<template>
  <v-row class="pa-3 pa-sm-0" style=" margin-top: 50px; margin-bottom: 50px" justify="center">
    <v-col style="border: 1px solid var(--v-primary-base); border-radius: 15px" class="pa-4" cols="12" sm="10">
      <div v-html="studio.value"></div>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      studio: null,
    };
  },
  mounted() {
    this.fetchStudio();
   
  },
  methods: {
    ...mapActions("studios", ["getStudioByToken"]),

    fetchStudio() {
      this.getStudioByToken(this.$route.params.token).then((studio) => {
        this.studio = studio;
        this.studio = this.studio.user.setting.find((x) => {
          return x.key == "page_policy_appointment";
        });
        console.log(this.studio);
      });
    },
  },
};
</script>